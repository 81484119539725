import React from "react"
import classnames from 'classnames'
// import Img from "../components/image-transform"

const Quote = ({content}) => (
  <div className={
    classnames('quote-holder', {
      blue: content.bgColor === 'blue'
    })
  }>
    <div className="container">
      {
        content.quotes.map(({heading, parag, profile, quote} , i) => (
          <div className="quote-item" key={i}>
            <div className="quote-content">
              <h2 className="quote-heading">{heading}</h2>

                {parag.map((items, j) =>
                  <div key={j} className="quote-info">
                    <p className="quote-title">{items.title}</p>
                    <p className="quote-text">{items.text}</p>
                  </div>
                )}
            </div>
            <div className="quote-profile">
              <div className="quote-img">
                <div className="img-bg">
                  {/* <Img filename={profile}  alt="profile" /> */}
                  <img src={profile} alt="profile"/>
                </div>
                <p className="quote">{quote}</p>
              </div>
            </div>
          </div>
        ))
      }
    </div>
  </div>
)

export default Quote
